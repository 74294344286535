<template lang='pug'>
  #GPT.main-page.GPT-color.page-noScroll.pb-0.position-relative
    loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999' attach)
    loadingOverlay(v-if='delLoading' message='Loading . . .' :zIndex='999' attach)
            v-progress-circular(indeterminate color='primary')
    div.height_100(v-if='!loading')
      div(:href='"/upgpt/MeetingRecap"' style='margin-left:2.7%;').d-flex.align-start.justify-start
        v-breadcrumbs.pa-0.px-3.pb-2.ellipsis()
          v-breadcrumbs-item(v-for="item in breadcrumbs" :key="item.text" )
            .detail_a
              a(:href='item.href')
                v-icon(size='23' style='margin-bottom:2px;').pr-1 mdi-chevron-double-left
                span {{item.text}}
      #page-inner.pa-3.d-flex.justify-start.pb-0.pl-0(style='width:95.5%;margin-left:2.7%;' :class='isEdit? "pt-1":"pt-4"')
        v-row.d-flex.justify-between-space
          v-col.d-flex(cols='8')
            h4.f-text-highlight-word(v-if='!isEdit') {{meetingData.name}}
            v-btn.pb-2( icon text color='gary' @click='onEdit' v-if='!isEdit')
                v-icon(size='20') icon-edit
            p.d-flex.align-center.input-has-label.pt-0.mb-1(v-else )
              v-text-field.pt-0.pb-0(v-model='meetingData.name' :readonly='!isEdit'  :rules="formRule.nameRules") 
                  template(v-slot:append)
                      v-icon.mr-1.mt-2(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                      v-icon.mt-2(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                      v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                      v-icon(size='18' v-if='!isEdit && !loading' @click='onEdit()') icon-edit
          v-col(cols='4' :style='isEdit ? "margin-top:2px;":"margin-top:-10px;"').d-flex.justify-end
            v-btn.elevation-0(v-if='meetingData.record_type=="opportunity"' color='#f19547' @click='updateCRM'  dark height='28px')
                span {{$t('GENERAL.UPDATE')}} CRM
            v-menu(offset-y="")
              template(v-slot:activator="{ on, attrs }")
                v-btn.elevation-0.ml-2(v-on="on" v-bind="attrs" color='#18bedf' dark height='28px' @click='toggleArrowIcon()') AI Email
                  v-icon.pl-1(v-if='ArrowIcon' size='15' class='v-icon notranslate fa fa-caret-up theme--light')
                  v-icon.pl-1(v-else size='15' class='v-icon notranslate fa fa-caret-down theme--light')
              v-list
                v-list-item(v-for="(item, index) in AiEmailItems" :key="index" @click="onSelectAiEmailItem(item)" :title='item.title')
                  v-list-item-title {{ item.title }}
            v-btn.pb-1( icon text color='gary' @click='onDelete' )
                v-icon(size='18') icon-remove
      #page-inner.pa-3.d-flex.justify-center.pt-0(style='width:100%;' )
        v-card.deal-tab-card.rounded-card(style='width:95%;' flat)
          v-row.account-info(no-gutters='no-gutters' align="center" justify="center")
            v-col(style='max-width:63%;')
                v-card-title(:class='!isEdit? " pa-0 pb-2 mt-2":" pa-0 pt-0 mt-2"')
                    div.pl-4.pr-4.d-flex.align-center(width="50px" style='margin-bottom:-67px;')
                      v-img.ml-2(src="../assets/images/summary.png" class="white--text align-end"  width="50px")
                .knowledges-detail-card-text.pt-0.pb-3
                  v-card-text.mt-0.pa-0.knowledges-card-text()
                        v-row(no-gutters )
                            v-col(cols='auto' ref="nameWidth").d-flex
                                h5.f-text-highlight-word.ellipsis(:class='isEdit? "pt-3":"pt-1"') {{ $t("GPT_MEETINGRECAP.MS") }}
                                v-btn(:title='$t("GPT_MEETINGRECAP.SUMMARY")' :class='isEdit? "pt-2":"pb-2"' v-if='!meetingData.meeting_summary' icon text color='gary' @click='giveMeSummary')
                                  v-icon(size='18') mdi-auto-fix
                        v-form(v-model='valid' ref='formContact')
                          v-row(no-gutters)
                              v-col.pr-md-4(cols='12' md='12' :title='meetingData.meeting_summary').my-1.pt-0.mt-0
                                  p.d-flex.align-start.input-has-label.mr-12.small-line-height.input-has-label-no-border
                                    //- label {{$t('CONTACT_DETAIL.DESCRIPTION')}}
                                    v-textarea.span.textarea-style.pt-0.mt-0(v-model='meetingData.meeting_summary' :readonly='!isEdit' solo no-resize rows='4')
                                      template(v-slot:append)
                                        v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                        v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                        v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                                        v-icon(size='18' v-if='!isEdit && !loading' @click='onEdit()') icon-edit
                        .d-flex.align-center.justify-md-space-between
                          v-row(no-gutters)
                              v-col(cols='').d-flex.justify-start
                                  p.d-flex.align-center.justify-center.pt-2(style='white-space:nowrap;') {{$t("GPT_MEETINGRECAP.MS_TIME")}} : 
                                    span.span.pl-1  {{getFormatTime(meetingData.created_on)}}
                              v-col(cols='auto' style='margin-top:-7px;').d-flex.justify-end
                                  div.d-flex.justify-end
                                    p.pt-4(style='white-space:nowrap;') {{$t("GPT_MEETINGRECAP.OWNER")}} :
                                    v-list-item(style='width:200px')
                                      v-list-item-avatar.ma-0.mr-2.knowledges-avatar-32(color='aliceBlue')
                                        span.span(v-if="meetingData.owner.usersetting.photo === ''") {{ meetingData.owner.last_name }}
                                        img(v-else :src="meetingData.owner.usersetting.photo" :alt="meetingData.owner.usersetting.last_name" )
                                      v-list-item-content.pa-0.user_icon
                                          span.span.t-primary(class='ellipsis') {{meetingData.owner.last_name+" "}}{{meetingData.owner.first_name}}
            v-col(style='max-width:37%;')
              .MeetingRecap-detail-subcard.pr-7
                v-card.rounded-card(flat style='height:100%;')
                  v-card-title.pa-0.pt-1.d-flex.align-center.justify-md-space-between
                    div.pa-2.d-flex.align-center(width="30px")
                      v-img.ml-2(id='' src="../assets/images/AI_C.png" class="white--text align-end"  width="35px")
                      h5.f-text-highlight-word.ellipsis.pl-3.pt-3 {{$t("GPT_MEETINGRECAP.ANR")}}
                  .pa-0.pt-0.d-flex.justify-md-space-between
                    v-card-text.pt-0(style='padding-left:70px;')
                      v-row.pt-2(no-gutters)
                          v-col(cols='auto' ref="nameWidth")
                              p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width
                                label.pl-0.t-black {{$t("GPT_MEETINGRECAP.AN")}} : 
                                span(v-if='meetingData.ai_notebook_id') {{meetingData.ai_notebook_id.name}}
                          //- v-col(cols='1' v-if='!meetingData.ispublic')
                          //-     v-btn.pb-1(height='15' icon text color='gary' @click='onEditSub' v-if='!isEditSub')
                          //-         v-icon(size='15') icon-edit
                      //- p(v-else).d-flex.align-center.knowledge-subcard-input
                      //-   label.pr-4 {{$t("GPT_MEETINGRECAP.AN")}} :
                      //-   v-autocomplete.no-border(v-model='meetingData.robots' return-object multiple solo :readonly='!isEditSub' :class='!isEditSub ? "remove-arrow" : ""' :items='chatBotList' item-text='bot_name' item-value='id'  :menu-props='{closeOnClick:true}')
                      //-       template(v-slot:append-outer)
                      //-           v-icon.mr-1(size='18' color="green" v-if='isEditSub' @click='confirmEditDetailSub()') icon-check
                      //-           v-icon(size='18' color="red" v-if='isEditSub' @click='cancelEditDetailSub()') mdi-window-close
                      //-           v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                      v-row.pt-5(no-gutters v-if='!loading && !isEditSub && meetingData.record_type=="opportunity"')
                          v-col(cols='auto' ref="nameWidth")
                              p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width
                                label.pl-0.t-black {{$t("GPT_MEETINGRECAP.OPPTY")}} : 
                                span.ellipsis.t-primary.pointer(v-if='meetingData.opportunity_id' @click='openTab(meetingData.opportunity_id.id)') {{meetingData.opportunity_id.name}}
                          v-col(cols='2')
                              v-btn.pb-1(height='15' icon text color='gary' @click='onEditSub' v-if='!isEditSub')
                                  v-icon(size='15') icon-edit
                      p(v-else-if='!loading && isEditSub && meetingData.record_type=="opportunity"' :class='isEditSub? "pt-3":""').d-flex.align-center.knowledge-subcard-input.input-has-label-no-border
                        label.pr-4.ml-1 {{$t("GPT_MEETINGRECAP.OPPTY")}} : 
                        //- v-autocomplete(v-model="filterSet[index].search_word" :rules='formRule.requireRule' :label= `$t('FILTERSETTING.PRODUCT')` :items="productData" item-text="name" item-value="id" :search-input.sync="search_product[index]" :loading="product_loading" @focus='get_current_index(index)' multiple chips small-chips :no-data-text=`$t('FILTERSETTING.INPUT_PRODUCT_TO_SEARCH')`)
                        v-col.pt-0.pb-0(cols='10')
                          v-autocomplete.pl-5.no-border(v-model='meetingData.opportunity_id' return-object solo :readonly='!isEditSub' :class='!isEditSub ? "remove-arrow" : ""' :items='opptyList' item-text='name' item-value='id'  :menu-props='{closeOnClick:true}' :search-input.sync="search_oppty" :loading="oppty_loading")
                              template(v-slot:prepend-item)
                                v-list-item
                                  v-btn(width="120" color="blue" plain border-0 @click='showAddDealDialog' )
                                    v-icon.mr-1(size="14") icon-add
                                    span {{$t('GENERAL.ADD_DEAL')}}
                              template(v-slot:selection="data")
                                  span(class='ellipsis' :title='data.item.name' style='font-size: 0.9rem;font-weight: 400;line-height: 1.375rem;letter-spacing: 0.0071428571em;padding-top:1px;margin-left:-2.3px;') {{data.item.name}}
                              template(v-slot:append-outer)
                                  v-icon.mr-1(size='18' color="green" v-if='isEditSub' @click='confirmEditDetailSub()') icon-check
                                  v-icon(size='18' color="red" v-if='isEditSub' @click='cancelEditDetailSub()') mdi-window-close
                                  v-progress-circular(size='18' v-if='loading' indeterminate color='primary')

      #page-inner.d-flex.justify-center.pt-0(style='width:95.5%;margin-left:2.2%;margin-top:4px;')
          v-row( align="center" justify="end")
            v-col(cols='4').pr-2
              v-card.deal-tab-card.rounded-card.MeetingRecapMinH( flat)
                v-card-title.pb-1
                  div.pa-2.d-flex.align-center.pt-0(width="30px")
                      v-img.ml-2(id='' src="../assets/images/note.png" class="white--text align-end"  width="30px")
                      h5.f-text-highlight-word.ellipsis.pl-3.pt-3 {{$t("GPT_MEETINGRECAP.AN")}}
                v-card-text(style='max-width:60vw;').d-flex.align-center.justify-center
                  .scrollbar.MeetingRecapMinH_Chat(style='width:100%;').pl-5
                    meetingRecapOpptyInput(v-if='meetingData.record_type=="opportunity"' ref="meetingRecapOpptyInput" :oppty_data='meetingData.ai_notes' :aiNotebookData='aiNotebookData' @emitUpdateAiNote='onUpdateAiNote')
                    meetingRecapAinoteInput(v-else-if='meetingData.record_type=="ai_notes"' ref="meetingRecapAinoteInput" :oppty_data='meetingData.ai_notes' :aiNotebookData='aiNotebookData' @emitUpdateAiNote='onUpdateAiNote')
            v-col(cols='4').pl-2.pr-2
              v-card.deal-tab-card.rounded-card.MeetingRecapMinH( flat)
                v-card-title.pb-1.justify-space-between
                  div.px-2.d-flex.align-center
                    v-img.ml-2(id='' src="../assets/images/note.png" class="white--text align-end"  width="30px")
                    h5.f-text-highlight-word.ellipsis.pl-3.pt-3 {{$t("GPT_MEETINGRECAP.SUGGEST_TASKS")}}
                  div
                    v-btn.elevation-0(v-if="createMession.length == 0" icon :title='$t("GPT_MEETINGRECAP.ANALYZE")'    @click='giveMeSummary' style='width: 80%;'  ) 
                      v-icon(size='18') mdi-auto-fix
                v-card-text.pa-4(style='max-width:60vw;').d-flex.align-center.justify-center
                  .scrollbar.MeetingRecapMinH_Chat(style='width:100%;').pl-5
                    div.d-flex.align-center.justify-space-between.pb-4(v-for="(createMessionItem,index) in createMession" :key="index" )
                      div
                        p.t-black {{ createMessionItem.messionDescription.subject }}
                      div
                        v-btn.mb-4.elevation-0(v-if="!createMession[index].iscreated" height="28px" color='success' @click='showCreateTaskDialog(index)' ) {{$t("GPT_MEETINGRECAP.CREATE_TASKS")  }}
                        v-btn.mb-4.elevation-0(v-if="createMession[index].iscreated" height="28px" dark color='rgb(24, 190, 223)' @click='showEditActivityDialog(createMessionItem.binding_activity_id,index)' ) {{$t("GPT_MEETINGRECAP.VIEW_TASKS")  }}
            v-col(cols='4').pl-2
              v-card.deal-tab-card.rounded-card.MeetingRecapMinH(flat)
                v-card-title
                  v-row
                    v-col(cols='4')
                      span(style='white-space:nowrap;') {{$t("GPT_MEETINGRECAP.TS")}}
                    v-col(cols='8')
                      v-text-field.search_border.no-border.d-flex(v-model='searchMsg' flat dense :placeholder=`$t("GENERAL.SEARCH")` @keydown.enter.prevent="goSearchMsg")
                        template(slot='prepend-inner')
                          v-icon(size='16').pt-1.pl-1 icon-search
                        template(v-slot:append)
                            div(v-if='lastSearchMsg!=""' style='padding-top:2px').d-flex
                              span.small_word.pt-1(style='min-width:20px;') {{searchMsgCount==0? 0:selectedMsgIndex+1}}/{{searchMsgCount}}
                              v-icon(size='20' color=""  @click='msgUp()' ) mdi-chevron-up
                              v-icon(size='20' color=""  @click='msgDown()' ) mdi-chevron-down
                        template(v-if='searchMsg || lastSearchMsg!=""' v-slot:append-outer)
                            v-icon(size='16' color="red"  @click='cancelMsgSearch') mdi-window-close
                v-card-text.pt-2
                  .scrollbar.MeetingRecapMinH_Chat()
                    v-spacer.mt-10.small_space(v-for='item,index in messageData' :key='"msg-"+item.index' :ref="'msg-'+item.index" id="trackMsg" )
                      v-row.chat_icon_div.d-flex.justify-start(color="rgba(0,0,0,0)" flat class="mb-0")
                        //- div.chat_icon_div(v-if='item.question')
                        //-   v-btn.chat_icon_bottom(width='0' height='32' color='green' plain border-0 @click='onCopyMsg(item.question)') {{$t('GENERAL.COPY')}}
                        v-row
                            v-col(style='max-width:40px' justify='center' align='center')
                                v-avatar(class="" size="40" elevation="10")
                                    v-icon.chat_icon_backgroung_color(:title=`item.name`) {{ item.name.slice(0,1) }}
                                //- span(style='white-space:nowrap;font-size:0.75rem;') {{item.time}}
                                //- span(style='white-space:nowrap;') {{getLittleFormatTime(item.time).slice(-5)}}
                            v-col.pa-0.pl-8(style='margin-top:8px;')
                                v-card.rounded-card(class="" color=""  flat)
                                    div.px-0.pb-6()
                                        div.d-flex.justify-start.mb-0
                                            span(style='font-weight:600;') {{item.name}} 
                                            span.pl-3(style='white-space:nowrap;font-size:0.75rem;padding-top:1px;color:gray;') {{item.time}}
                                        div.upchat-message-div.d-flex.justify-start.mb-4
                                            span.can-select-text(v-html='highlight(item.text)' style='line-height:1.7')                 
            //- v-col(cols='4').pr-2
            //-   v-card.deal-tab-card.rounded-card(style='width:100%;' flat min-height='300px')
            //- v-col(cols='4').pr-2
            //-   v-card.deal-tab-card.rounded-card(style='width:100%;' flat min-height='300px')
            //- v-col(cols='4').pl-2
            //-   v-card.deal-tab-card.rounded-card(style='width:100%;' flat min-height='300px')
    v-dialog(v-if='meetiEmailDialog' v-model="meetiEmailDialog" max-width='900' @keydown.esc='onEmitMeetiEmailDialog(false)' persistent)
      meetiEmail-dialog(@emitMeetiEmailDialog='onEmitMeetiEmailDialog' :meetingData='meetingData' :type='aiMailType')
    v-dialog(v-model='createTaskDialog' max-width='630' v-if='createTaskDialog')
      createTask-dialog(@emitCreateTaskDialog='onEmitCreateTaskDialog' :add-activity='addActivity' :activity-type='activityTypeList' :ownerList='ownerList' :newDateTime='newDateTime' :regarding_data = 'regardingData' :createMession_index = 'createMession_index')
    v-dialog(v-model='editActivityDialog' max-width='630' v-if='editActivityDialog')
      edit-activity(@emitEditActivityDialog='onEmitEditActivityDialog' :activityId='activityId' :activity-type='activityTypeList' :owner-list='ownerList' :canEdit='canEdit' :createMession_index='createMession_index')
    v-dialog(v-model='addDealDialog' max-width='600'  v-if='addDealDialog')
      add-deal(@emitAddDealDialog='onEmitAddDealDialog' @emitNewDealData='onEmitNewDealData' :add-deal='addDealData' )   
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import addDeal from '@/components/Dialog/Deal/addDeal';
import meetiEmailDialog from "@/components/GPT_Dialog/MeetingRecap/AiEmail/meetiEmail";
import createTaskDialog from "@/components/GPT_Dialog/MeetingRecap/createTask";
import editActivity from '@/components/Dialog/Activity/editActivity';
import i18n from '/common/plugins/vue-i18n.js';
import meetingRecapOpptyInput from "../components/GPT_Common/meetingRecapOpptyInput.vue";
import meetingRecapAinoteInput from "../components/GPT_Common/meetingRecapAinoteInput.vue";
import UpMeetingService from "../services/UpMeetingService";
import PreferenceService from "../services/PreferenceService";
import OrganizationDataService from "../services/OrganizationDataService";
import ActivityDataService from '../services/ActivityDataService';

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    editActivity,
    meetingRecapOpptyInput,
    meetingRecapAinoteInput,
    createTaskDialog,
    meetiEmailDialog,
    addDeal
  },
  data() {
    return {
      // other
      aiMailType:null,
      valid:true,
      isEdit:false,
      isEditSub:false,
      loading:false,
      delLoading:false,
      breadcrumbs: [{
              text: i18n.t('GPT_MENU.BACKTO') + i18n.t('GPT_MEETINGRECAPS.TITLE'),
              disabled: false,
              href: '/upgpt/MeetingRecap',
          }
      ],
      formRule: {
          nameRules: [
              (v) => !!v || i18n.t('RULE.RULE_R'),
              (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
          ],
      },
      AiEmailItems: [
        {title: i18n.t('AL_MAIL.FOLLOWUP'), value: 'followUp'},
        {title: i18n.t('AL_MAIL.SUMMARY'), value: 'summary'},
        {title: i18n.t('AL_MAIL.CUSTOM'), value: 'customType'},
      ],
      createMession: [],
      ArrowIcon: false,
      // Dialog
      activityId: null,
      canEdit:true,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      meetiEmailDialog:false,
      editActivityDialog:false,
      createTaskDialog: false,
      createTask: {},
      addActivity: {},
      activityRegardingList: [],
      regardingData: '',
      createMession_index: '',
      addDealData: {},
      addDealDialog: false,

      newDateTime: null,
      // Data
      search_oppty:'',
      oppty_loading:false,
      selectedMsgIndex:-1,
      searchMsgList: [],
      searchMsgCount:0,
      opptyList:[],
      lastSearchMsg:'',
      searchMsg:"",
      meetingData:null,
      originMeetingData:null,
      originMeetingOppty:null,
      target_id : this.$route.params.id,
      mode:null,
      messageData:[],
      aiNotebookData:[],
      ownerList:[],
      activityTypeList: [],
      opptyDetail: {},
    };
  },
  async created() {
    this.loading = true;
    let userJson = window.localStorage.getItem('user');
    this.currentUser = JSON.parse(userJson);
    this.currentUser.is_staff ? this.isAdmin = true : this.isAdmin = false;
    await OrganizationDataService.getchangeowner('activities').then(response => {
      this.ownerList = response.data;
      this.ownerList.forEach(item => {
        if(item.first_name != null) {
          item.full_name = item.last_name + " " + item.first_name;
        }else{
          item.full_name = item.last_name;
        }
      })
    }).catch(response => {
        this.showErrorDialog(response);
    })
    await ActivityDataService.getActivityType().then(response => {
      this.activityTypeList = response.data;
    }).then(()=>{
      this.selectType = []
    }).catch(response => {
        console.log(response)
    })
    await this.getDetailData();
    this.loading = false;
  },
  destroyed() {
  },
  methods: {
    toggleArrowIcon(){
      this.ArrowIcon = !this.ArrowIcon;
    },
    openTab(id){
      window.open("/Deal/"+id, '_blank').focus();
    },
    onSelectAiEmailItem(item){
      this.meetiEmailDialog = true;
      if(item.value == 'summary'){
        this.aiMailType = 'summary';
      }
      if(item.value == 'customType'){
        this.aiMailType = 'customType';
      }
      if(item.value == 'followUp'){
        this.aiMailType = 'followUp';
      }
    },
    cancelMsgSearch(){
      this.searchMsg="";
      this.searchMsgList = [];
      this.searchMsgCount = 0;
      this.selectedMsgIndex = -1;
      this.lastSearchMsg = "";
    },
    scrollToMsg(){
      if(this.searchMsgList.length>0){
        this.$refs["msg-"+this.searchMsgList[this.selectedMsgIndex].index][0].scrollIntoView({ behavior: 'smooth' })
      }
    },
    async goSearchMsg(){
      if(this.lastSearchMsg != this.searchMsg && this.searchMsg != ''){
        this.searchMsgList = [];
        this.messageData.forEach((el)=>{
          if(el.text.includes(this.searchMsg)){
            let item = JSON.parse(JSON.stringify(el));

            this.searchMsgList.push(item);
          }
        })
        this.searchMsgCount = this.searchMsgList.length;
        this.selectedMsgIndex = this.searchMsgList.length-1;
        setTimeout(()=>{
          this.scrollToMsg();
        },500)
        this.lastSearchMsg = this.searchMsg;
      }
    },
    msgUp(){
      if(this.searchMsgList.length==0){
        return;
      }
      this.clickToClose = false;
      this.selectedMsgIndex > 0 ? this.selectedMsgIndex--:null ;
      this.scrollToMsg();
      setTimeout(()=>{
        this.clickToClose = true;
      },0)
    },
    msgDown(){
      if(this.searchMsgList.length==0){
        return;
      }
      this.clickToClose = false;
      this.selectedMsgIndex < this.searchMsgList.length-1 ? this.selectedMsgIndex++:null ;
      this.scrollToMsg();
      setTimeout(()=>{
        this.clickToClose = true;
      },0)
    },
    highlight(text) {
        if(this.searchMsgList.length == 0) {
            return text;
        }
        return text.replace(new RegExp(this.lastSearchMsg, "gi"), match => {
            return '<span class="highlight">' + match + '</span>';
        });
    },
    getLittleFormatTime(data){
      let options = {year: 'numeric', month: '2-digit', day: '2-digit',hour: '2-digit',minute: '2-digit',hour12: false };
        let today = new Date(data);
        return today.toLocaleDateString("zh-TW", options).replaceAll('/','-'); 
    },
    set_title(){
        const title = localStorage.getItem('title');
        let title_name = this.meetingData.name;
        let tab_name = '';
        tab_name = this.meetingData.name;
        document.title = title+' : '+title_name +' – UpGPT';
        return tab_name;
    },
    async getDetailData(){
      await UpMeetingService.getMeetingRecapDetail(this.target_id).then((response)=>{
          this.meetingData = response.data;
          this.aiNotebookData = this.meetingData.ai_notebook_id;
          this.meetingData.ai_notes = this.meetingData.ai_notes? this.meetingData.ai_notes: {};
          if(response.data.transcript){
              this.messageData = JSON.parse(response.data.transcript);
          }
          let index = -1;
          this.messageData.forEach(el=>{
              el.index = index++;
              el.text = el.content.map(el=>{return el.text}).join("<br>");
              // el.text = el.content.join("<br>");
          })
          if(this.meetingData.opportunity_id != null){
            this.opptyList.push(this.meetingData.opportunity_id);
          }
          if(this.meetingData.suggested_tasks != null){
            this.createMession =  this.meetingData.suggested_tasks;
          }
          this.set_title();
      }).catch(response => {
          // Redirect to PermissionError page if error status is 403
          if (response.response.status == 403)
          {
              this.$router.replace('/upgpt/PermissionError');
          }
          else if(response.response.status == 404)
          {
              this.$router.replace('/upgpt/PageNotFound');
          }
      });
    },
    async onUpdateAiNote(data){
      let editData = {
        "id":this.meetingData.id,
        "ai_notes":data,
        "name":this.meetingData.name,
        "meeting_time":this.meetingData.meeting_time,
      }
      await UpMeetingService.editMeetingRecap(editData).then((response)=>{
        this.meetingData = response.data;
      })
    },
    onDelete(id){
      this.mode = "delete";
      this.delete_id = id;
      this.showMessageDialog(i18n.t("GPT_MEETINGRECAPS.DELETE"))
    },
    async updateCRM(){
      let valid = this.$refs.meetingRecapOpptyInput.validate();
      if(valid && this.meetingData.opportunity_id){
        this.loading = true;
        let key = "";
        await PreferenceService.getApiKey().then((response)=>{
          if(response.data == ""){
            return;
          }
          key = response.data;
        })
        let data={
          "opportunity_id":this.meetingData.opportunity_id.id,
          "ai_notes":this.meetingData.ai_notes,
          "meeting_data":this.meetingData,
          "meeting_time": this.meetingData.meeting_time,
        }
        await UpMeetingService.updateCRM(data,key).then(()=>{
          this.showSuccessDialog();
          this.loading = false;
        })
      }else{
        this.showErrorDialog(i18n.t("GPT_MEETINGRECAP.VALIDATE"));
      }
    },
    resetData(){
      this.mode = "reset";
      this.message = i18n.t("GPT_KNOWLEDGE.CLEAR_MSG");
      this.messageDialog = true;
    },
    getChatBotName(){
      let names = this.meetingData.robots.map(el=>{return el.bot_name}).join();
      return names
    },
    getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = new Date(data);
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }
    },
    onEdit(){
        this.isEdit = true;
        this.originMeetingData = JSON.parse(JSON.stringify(this.meetingData));
    },
    async confirmEditDetail(){
      let edit_data = {
        "id" : this.meetingData.id,
        "meeting_summary" : this.meetingData.meeting_summary,
        "name" : this.meetingData.name
      }
      await UpMeetingService.editMeetingRecap(edit_data).then((response)=>{
        this.meetingData.name = response.data.name;
        this.meetingData.meeting_summary = response.data.meeting_summary;
        this.meetingData.updated_on = response.data.updated_on;
      })
      this.isEdit = false;
    },
    async giveMeSummary(){
      this.loading = true;
      let key = "";
      await PreferenceService.getApiKey().then((response)=>{
        if(response.data == ""){
          return;
        }
        key = response.data;
      })
      let data={
        "meeting_recap_id":this.meetingData.id,
        "language":"Tradionnal Chinese"
      }
      await UpMeetingService.giveMeSummary(data,key).then((response)=>{
        if(response.data=="empty"){
          this.showErrorDialog(i18n.t("GPT_MEETINGRECAP.NO_T"));
        }else if(response.data=="deleted"){
          this.showErrorDialog(i18n.t("GPT_MEETINGRECAP.DELETED_MEETING_RECAP"));
        }else if(response.data=="exist"){
          this.showErrorDialog(i18n.t("GPT_MEETINGRECAP.EXIST_SUMMARY_AND_TASK"));
        }else{
          this.getDetailData();
        }
        this.loading = false;
      })
    },
    cancelEditDetail(){
      this.isEdit = false;
      this.meetingData = this.originMeetingData;
    },
    onEditSub(){
        this.isEditSub = true;
        this.originMeetingOppty = JSON.parse(JSON.stringify(this.meetingData.opportunity_id));
    },
    async confirmEditDetailSub(){
      let edit_data = {
        "id" : this.meetingData.id,
        "name": this.meetingData.name,
        "opportunity_id_id" : this.meetingData.opportunity_id? this.meetingData.opportunity_id.id:null,
        "ai_notebook_id_id": this.meetingData.ai_notebook_id.id
      }
      await UpMeetingService.editMeetingRecap(edit_data).then((response)=>{
        this.meetingData.opportunity_id = response.data.opportunity_id;
        this.meetingData.ai_notebook_id = response.data.ai_notebook_id;
      })
      this.isEditSub = false;
    },
    cancelEditDetailSub(){
      this.isEditSub = false;
      this.meetingData.opportunity_id = this.originMeetingOppty ;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    async aiAnalyze(){
      this.loading = true;
      let senddata = {
          "meeting_recap_id":this.target_id,
        };
      let key = "";
      await PreferenceService.getApiKey().then((response)=>{
        if(response.data == ""){
          return;
        }
        key = response.data;
      })
      await UpMeetingService.AiTasksGenerate(senddata,key).then((response)=>{
                  if (response.data == 'empty_summary'){
                    this.loading = false;
                    this.showErrorDialog(i18n.t('GPT_MEETINGRECAP.EMPTY_SUMMARY_TASK'));
                  }
                  else{
                    this.createMession = response.data
                    this.meetingData.suggested_tasks = this.createMession
                    this.loading = false;
                  }
                })
    },
    // Dialog Area ----------------------------------------- [Start]
    
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
      if(this.mode == 'delete'){
        this.mode = null;
        this.$router.push('/upgpt/Knowledges');
      }
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async showCreateTaskDialog(index){
      let array_oppty = [];
      if(this.meetingData.opportunity_id != null){
        array_oppty.push( {
          id: this.meetingData.opportunity_id.id,
          name: this.meetingData.opportunity_id.name,
          list_type : "oppty",
          list_name : this.meetingData.opportunity_id.name
        }
         )
      }
      this.regardingData = array_oppty.length > 0 ? array_oppty[0] : null;
      this.createMession_index = index
      this.addActivity={
        subject:this.createMession[index].messionDescription.subject,
        start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)+ " " + (new Date()).getHours().toString().padStart(2, '0') + ":" + (new Date()).getMinutes().toString().padStart(2, '0'),
        due_date: this.createMession[index].messionDescription.due_date,
        completed : false,
        priority : "MEDIUM",
        progress : 0,
        remark: this.createMession[index].messionDescription.task_content,
        activity_type:  this.activityTypeList.find(e => e.id == 100002),
        activity_contact:null,
        activity_org:null,
        owner: this.ownerList.find(e => e.id == this.currentUser.userid),
        regarding_case:null,
        regarding_oppty: this.regardingData,
      }
      this.createTaskDialog = true;
    },
    async showEditActivityDialog(activity_id, createMession_index){
      this.activityId = activity_id;
      this.createMession_index = createMession_index;
      await ActivityDataService.getActivityDetail(this.activityId).then(()=>{
        this.editActivityDialog = true;
      }).catch(response => {
          if(response.response.status == 404)
          {
            this.createMession[createMession_index].binding_activity_id = null;
            this.createMession[createMession_index].iscreated = false;
            this.meetingData.suggested_tasks = this.createMession
            let edit_data = {
              "id" : this.meetingData.id,
              "name" : this.meetingData.name,
              "suggested_tasks": this.meetingData.suggested_tasks,
            }
            UpMeetingService.editMeetingRecap(edit_data).then((response)=>{
              this.meetingData.suggested_tasks = response.data.suggested_tasks;
              this.createMession = this.meetingData.suggested_tasks;
              this.showErrorDialog(i18n.t('GPT_MEETINGRECAP.TASK_CANT_FIND_ACTIVITY'));
            })
          }
      });
    },
    onEmitMeetiEmailDialog(val){
      if(val){
        this.showSuccessDialog();
      }
      this.meetiEmailDialog = false;
    },

    async onEmitCreateTaskDialog(return_data){
      if (return_data){
        this.createMession[return_data.task_index].binding_activity_id = return_data.activity_id;
        this.createMession[return_data.task_index].iscreated = true;
        this.meetingData.suggested_tasks = this.createMession;
        let edit_data = {
          "id" : this.meetingData.id,
          "name" : this.meetingData.name,
          "suggested_tasks": this.meetingData.suggested_tasks,
        }
        await UpMeetingService.editMeetingRecap(edit_data).then((response)=>{
          this.meetingData.suggested_tasks = response.data.suggested_tasks;
          this.createMession = this.meetingData.suggested_tasks;
        }).finally(()=>{
          this.createTaskDialog = false;
          this.showSuccessDialog();
        })
      }
      else
        this.createTaskDialog = false;
    },
    onEmitEditActivityDialog(val){
      if(val=='edit'){
        //
      }
      if(val=="delete"){
        //
      }
      if(typeof(val)=="number"){
        this.createMession[val].binding_activity_id = null;
        this.createMession[val].iscreated = false;
        this.meetingData.suggested_tasks = this.createMession
        let edit_data = {
          "id" : this.meetingData.id,
          "name" : this.meetingData.name,
          "suggested_tasks": this.meetingData.suggested_tasks,
        }
        UpMeetingService.editMeetingRecap(edit_data).then((response)=>{
          this.meetingData.suggested_tasks = response.data.suggested_tasks;
          this.createMession = this.meetingData.suggested_tasks;
        })
      }
      this.editActivityDialog = false;
    },
    async onEmitMessageDialog(val) {
      if(val){
        this.delLoading = true;
        this.messageDialog = false;
        if(this.mode == "delete"){
          await UpMeetingService.deleteMeetingRecap(this.meetingData.id).then(()=>{
            this.$router.push('/upgpt/MeetingRecap');
            this.delLoading = false;
            this.mode = null;
          });
        }
      }
      this.messageDialog = false;
    },
    showAddDealDialog(){
      this.addDealDialog = true;
      this.addDealData = {
        name: null,
        primary_contact: null,
        oppty_org: null,
        stage_type: this.meetingData.ai_notebook_id.business_process_id ,
        amount: null,
        expected_close_date: this.meetingData.ai_notes.expected_close_date ? this.meetingData.ai_notes.expected_close_date : null,
        forecast_category: 1,
        };
    },
    onEmitAddDealDialog(val) {
      this.addDealDialog = false;
      this.selectedRows = [];
      if (val) {
          this.successMode = 'ADD';
          this.showSuccessDialog();
          }
    },
    onEmitNewDealData(data){
      //console.log('onEmitNewDeal',data)
      let nweOpportunity_id = {
        id: data.id,
        name: data.name,
      }
      this.meetingData.opportunity_id = nweOpportunity_id;
      if(this.meetingData.opportunity_id ){
            this.opptyList.push(this.meetingData.opportunity_id);
          }
    },
    async reFresh(){
      this.loading = true;
      await this.getDetailData();
      this.loading = false;
    },
    
  },
  watch:{
    async search_oppty(){
        if(!this.search_oppty){
          return
        }
        if (!this.oppty_loading) {
          this.oppty_loading = true;
          setTimeout(() => {
            UpMeetingService.opptyList(this.search_oppty,this.aiNotebookData.business_process_id)
            .then(response => 
            {  
                this.opptyList = response.data;
                this.oppty_loading = false;
            });
          }, 1000);
        }
        this.awaitingSearch = true;
    },
  }
};
</script>

<style lang="scss">
.small-line-height{
    .v-textarea textarea {
        line-height: 22px;
  }
}
.search_border{
  margin-top:0px;
  border: 1px solid rgb(163, 163, 163);
  border-radius: 5px;
}
</style>
<template lang='pug'>
  #GPT.main-page.GPT-color.page-noScroll.pb-0.position-relative
    //- loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    v-row#page-head.d-flex.align-center.justify-md-space-between
      v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='3' md='3')
        h1.my-0 {{$t('GPT_MENU.MEETINGRECAP')}}
      v-col(cols='9').pr-0
          v-row.d-flex.justify-end
            v-col(cols='5').d-flex.align-center.justify-md-space-between
              //- v-select(v-model='selectKnowledge' :title='getItemNameFromListById(Knowledges_list,selectKnowledge)' :items='Knowledges_list' item-value='id' item-text='name' prepend-inner-icon='mdi-book-open-variant' height='30' flat dense solo)
              v-text-field(v-model='search' flat dense  prepend-inner-icon='icon-search' solo)
              //- refreshBtn(@EmitRefresh='onEmitRefresh')
    #page-inner.scroll-x.pb-0.height_100vh
      .upload_page_height.scrollbar.px-3()
          v-container.pa-1.upload_page-container(max-content)
           v-data-table.dashboard-table.rounded-card.pl-3.elevation-2(
              style='text-align:start;'
              item-key="id",
              v-model="selectedRows" 
              :item-class="rowClass"
              :headers="fieldsHeader",
              :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
              :items="meetingList",
              :items-per-page="itemPerPage",
              :page.sync="page",
              :page-count="pageCount",
              :sort-desc.sync='sortByDesc' 
              :sort-by.sync="sortBy"
              multi-sort
              fixed-header,
              hide-default-footer="hide-default-footer",
              dense
              height='74vh'
              :loading='loading'
              loading-text='Loading . . .'
            )
              template( v-slot:item.name="{ item }" )
                  .ellipsis(:title='getI18nName(item.name)' height='36px')
                    a(:href="'/upgpt/MeetingRecap/' + item.id" class='ellipsis') {{ item.name }}

              template( v-slot:item.total="{ item }" )
                  .ellipsis(:title='item.total')
                    span {{item.total}}
              template(v-slot:item.owner='{ item }')
                    div(:title='item.owner.last_name+" "+item.owner.first_name' )
                        v-list-item-group(class='inline-edit-cell justify-start')
                            .ellipsis
                                v-list-item-avatar.d-inline-block.ma-0(color="aliceBlue", size="30")
                                    v-icon(v-if="item.owner == null") mdi-account 
                                    v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
                                    img(v-else :src='item.owner.usersetting.photo')
                                a(v-if='!!item.owner' :href='onMail(item.owner.email)' class='ellipsis').pl-3 {{ item.owner.last_name+' '+item.owner.first_name }}
              //- 執行
              template(v-slot:item.action='{ item }')
                .ellipsis
                    v-btn(icon='icon' @click='onEdit(item)')
                        v-icon(size='18') icon-edit
                    v-btn(icon='icon' @click='onDelete(item.id)')
                        v-icon(size='18') icon-remove
          
          label.d-flex.justify-end.mr-2.pt-3 {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}} 
          v-pagination(v-model='page' :length='pageCount' circle :total-visible="10" v-if='!loading')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='editDialog' width='400' content-class='statusDialog' v-if='editDialog')
      edit-dialog(@emitEditDialog='onEmitEditDialog' :edit_data='edit_data')
</template>

<script>
import refreshBtn from "@/components/Common/refreshBtn";
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';
import UpMeetingService from "@/services/UpMeetingService";
import editDialog from "@/components/GPT_Dialog/MeetingRecap/editMeetingRecap";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    refreshBtn,
    editDialog
  },
  data() {
    return {
      // other
      loading:false,
      isDelete:false,
      // Dialog
      editDialog:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      Knowledges_list:[{id:"0",name:i18n.t('GENERAL.ALL')}],
      search:"",
      selectKnowledge:"0",
      selectedRows:[],
      recordsDialog:false,
      records:null,
      snackbar:false,
      meetingList:[],
      fieldsHeader: [
        // { value: "data-table-select",align: "center",width: '50px',},
        { text: i18n.t('GPT_MEETINGRECAPS.NAME'), value: "name", align: "left" , width: '25%' , sortable: true, },
        // { text: i18n.t('GPT_MEETINGRECAPS.participants'), value: "created_on", align: "left" , width: '35%' , sortable: true, },
        { text: i18n.t('GPT_MEETINGRECAPS.OWNER'), value: "owner", align: "left" , width: '35%' , sortable: true, },
        { text: i18n.t('GPT_MEETINGRECAPS.TIME'), value: "time", align: "left" , width: '30%' , sortable: true, },
        { text: i18n.t('GENERAL.ACTIVE'), value: "action", align: "left" , width: '10%' , sortable: false, },
      ],
      awaitingSearch: false, 
      sortBy:[],
      sortByForSearch:[],
      sortByDesc:[],
      page: 1,
      pageCount: 0,
      pageDataCount:0,
      currentData:0,
      itemPerPage: 13,
      delete_id:null,
      mode:null,
      edit_data:null,
    };
  },
  async created() {
    await this.getListData();
  },
  destroyed() {
  },
  methods: {
    onEdit(data){
      this.edit_data = data;
      this.editDialog = true;
    },
    onEmitEditDialog(val,data){
      if(val){
        this.edit_data.name = data.name;
      }
      this.edit_data = null;
      this.editDialog = false;
    },
    onDelete(id){
      this.mode = "DELETE";
      this.delete_id = id;
      this.showMessageDialog(i18n.t("GPT_MEETINGRECAPS.DELETE"))
    },
    async getListData(){
      this.loading = true;
      await UpMeetingService.getMeetingRecapList(this.page,this.search,this.itemPerPage,this.sortByForSearch).then((response)=>{
        this.meetingList = response.data.list;
        this.pageCount = response.data.total_page;
        this.pageDataCount = response.data.pageDataCount;
        this.currentData = (this.page)*this.itemPerPage >= this.pageDataCount ? this.pageDataCount:(this.page)*this.itemPerPage;
        this.meetingList.forEach((item)=>{
          item.time = this.getFormatTime(item.updated_on);
        })
      }).finally(()=>{
        this.loading = false;
      });
    },
    showRecords(data){
      this.recordsDialog = true;
      this.records = data;
    },
    onCancel() {
      this.recordsDialog = false;
      this.records = null;
    },
    onCopyMsg(event){      
        const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
        clipboardData.writeText(this.records);
        this.snackbar = true
    },
    getI18nName(str){
      switch(str){
        case "Upload" :
          return i18n.t('UPLOADSTATUS.UPLOAD');
        case "Import" :
          return i18n.t('UPLOADSTATUS.IMPORT');
        case "PROCESSING" :
          return i18n.t('UPLOADSTATUS.PROCESSING');
        case "COMPLETE" :
          return i18n.t('UPLOADSTATUS.COMPLETE');
        case "FAIL" :
          return i18n.t('UPLOADSTATUS.FAIL');
        case "EMBEDDING" :
          return i18n.t('UPLOADSTATUS.EMBEDDING');
        case "UploadFolder" :
          return i18n.t('UPGPT.FOLDERUPLOAD');
        case "UploadQAFile" :
          return i18n.t('UPGPT.QAUPLOAD');
        case "UploadFile" :
          return i18n.t('UPGPT.FILEUPLOAD');
        case "WEB" :
          return i18n.t('UPGPT.WEBSITEANALYSIS');
        case "Contact" :
          return i18n.t('Bower_Title.Contact');
        case "Organization" :
          return i18n.t('Bower_Title.Company');
        case "Opportunity" :
          return i18n.t('Bower_Title.Oppty');
        case "Case" :
          return i18n.t('Bower_Title.Case');
        case "KnowledgeArticle" :
          return i18n.t('Bower_Title.KnowledgeArticle');
        default:
          return str;
      }
    },
    rowClass() {
        const rowClass = 'dataTableColor'
        return rowClass;
    },
    getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = new Date(data);
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }                
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    // Dialog Area ----------------------------------------- [Start]
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async onEmitMessageDialog(val) {
      if(val){
        if(this.mode == "DELETE"){
          await UpMeetingService.deleteMeetingRecap(this.delete_id).then(()=>{
            this.meetingList = this.meetingList.filter(el => el.id != this.delete_id);
            this.pageDataCount = this.pageDataCount-1;
            this.currentData = this.currentData-1;
          })
        }
      }
      this.mode = null;
      this.delete_id = null;
      this.messageDialog = false;
    },
    async onEmitRefresh(){
        this.loading = true;
        await this.getKnowledgeList();
        await this.getListData();
        this.loading = false;
    },
  },
  watch:{
    "selectKnowledge":async function(){
      await this.getListData();
    },
    "page":async function () {      
      await this.getListData()  
    },
    "search":async function () {      
      if (!this.awaitingSearch) {
				setTimeout(() => {
          this.page = 1
					this.getListData();
					this.awaitingSearch = false;					
				}, 1000);
				}
				this.awaitingSearch = true;  
    },     
    "sortByDesc":async function () {    
      this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
      for(let i in this.sortByDesc)
      {
        if(this.sortByForSearch[i] == 'time'){
          this.sortByForSearch[i] = 'updated_on';
        }
        if(this.sortByDesc[i] == true )
        {
          this.sortByForSearch[i] = '-'+this.sortByForSearch[i]
        }
      }
      await this.getListData()  
    },
  }
};
</script>